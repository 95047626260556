<template>
	<div class="tit_wrap">
		<h2>랭킹</h2>
		<div class="realtime">
			<div class="realtime_day">{{ day }}</div>
			<span class="realtime_time">{{ second }}</span>
			<button type="button">
				<i class="bx bx-revision" style="transition: 1s all" @click="refreshCurrentTime"></i>
			</button>
		</div>
		<select name="order" v-model="order" class="order_select2">
			<option value="0">실시간</option>
			<option value="1">일간</option>
			<option value="2">주간</option>
			<option value="3">월간</option>
		</select>
	</div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { ListByRankingStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';

const store = ListByRankingStore();

const order = ref('0'); // 실시간, 일간, 주간, 월간
const { date } = storeToRefs(store);

const rotate = ref(720); // 회전 각도 변수
const rotateStatus = ref(false); // 회전 이벤트 실행중 여부

// 랭킹 새로고침 버튼 클릭 시
const refreshCurrentTime = (e) => {
	const rotateEvent = setTimeout(() => {
		rotateStatus.value = false;
	}, 1000);
	if (!rotateStatus.value) {
		e.target.style.transform = `rotate(${rotate.value}deg)`;
		rotate.value += 720;
		rotateStatus.value = true;
		store.getSubRankingItems();
	} else {
		window.clearTimeout(rotateEvent);
	}
};
// const refreshRanking = () => {
// 	store.getSubRankingItems();
// };

watch(order, (newData) => {
	store.setOrder(newData);
	store.getSubRankingItems();
});

// 시간 업데이트 시 출력 시간 변경
const day = computed(() => {
	return date.value.format('YYYY. MM. DD');
});
const second = computed(() => {
	return date.value.format('HH : mm');
});
</script>

<style></style>
