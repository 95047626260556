<template>
	<div class="box_content_wrap">
		<RankingItemHead></RankingItemHead>
		<GenreTab @changeGenre="changeGenre"></GenreTab>
		<!-- <div class="line"></div> -->
		<div class="sub_list_box_wrap" v-if="itemList.length > 0">
			<table class="rank_list_table">
				<thead>
					<tr class="d_none_660">
						<th width="6%">순위</th>
						<th width="">제목</th>
						<th width="11%">작가</th>
						<th width="11%">성우</th>
						<th width="12%" class="d_none_660">청취자 수</th>
						<th width="11%" class="d_none_660">좋아요</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in itemList" :key="index" @click="goContentDetailPage(item)">
						<td>
							<ul class="rank_change_wrap">
								<li class="rank">{{ item.ranking }}</li>
								<li class="change_box_wrap">
									<ul class="change_box">
										<li>
											<i
												class="bx bxs-up-arrow up"
												v-if="calcRanking(item.pre_ranking, item.ranking) === 'up'"
											></i
											><i
												class="bx bxs-down-arrow down"
												v-if="calcRanking(item.pre_ranking, item.ranking) === 'down'"
											></i
											><i
												class="bx bx-minus nochange"
												v-if="calcRanking(item.pre_ranking, item.ranking) === 'same'"
											></i>
										</li>
										<li v-if="calcRanking(item.pre_ranking, item.ranking) === 'up'" class="up">
											{{ calcStep(item.pre_ranking, item.ranking) }}
										</li>
										<li v-if="calcRanking(item.pre_ranking, item.ranking) === 'down'" class="down">
											{{ calcStep(item.pre_ranking, item.ranking) }}
										</li>
									</ul>
								</li>
							</ul>
						</td>
						<td class="img_txt_set">
							<img v-if="checkAdultUser(item)" :src="`${$image_host}/contents/${item.thumb_path}`" />
							<img v-else src="@/assets/images/adult_thumbnail.png" />
							<!-- <img alt="thumbnail" :src="`${$image_host}/contents/${item.thumb_path}`" /> -->
							<span>{{ item.opus_title }}</span>
						</td>
						<td class="writer">{{ item.exposure_author }}</td>
						<td class="voice_actor">{{ item.exposure_voice_actor }}</td>
						<td class="d_none_660">{{ item.view_cnt }}</td>
						<td class="d_none_660">{{ item.fav_cnt }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="no_data" v-else>
			<span class="ic"></span>
			<span class="tit">Sorry</span>
			<p>데이터가 없습니다.</p>
		</div>
		<Pagination
			v-if="itemList.length > 0"
			@changePage="changePage"
			:page="page"
			:totalPage="totalPage"
		></Pagination>
		<div class="double_bnn_wrap">
			<EventItemHead></EventItemHead>
			<EventItemList></EventItemList>
		</div>
	</div>
</template>

<script setup>
import EventItemHead from '@/shared-components/event/EventItemHead.vue';
import EventItemList from '@/shared-components/event/EventItemList.vue';
import Pagination from '@/shared-components/Pagination.vue';
import GenreTab from '@/shared-components/GenreTab.vue';
import RankingItemHead from '@/components/sub/ranking/RankingItemHead.vue';

import { ListByRankingStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import { calcRanking, calcStep } from '@/common-methods/computedValue';
import goContentDetailPage from '@/common-methods/goContentDetailPage';
import { checkAdultUser } from '@/common-methods/checkAccount';

// 페이지 로드 첫 리스트 호출
const store = ListByRankingStore();
store.setGenre('ALL');
store.getSubRankingItems();
const { itemList, genre, page, totalPage } = storeToRefs(store);

// 장르 탭 emit 함수
const changeGenre = (genre) => {
	// store.$patch({ page: 1, genre });
	store.setGenre(genre);
	store.getSubRankingItems();
};

// 페이지네이션 emit 함수
const changePage = (page) => {
	store.setPage(page);
	store.getSubRankingItems();
};
</script>

<style scoped>
@media screen and (min-width: 851px) {
	.sub_list_box_wrap {
		/* min-height: 975px; */
		min-height: 552px;
	}
}

@media screen and (min-width: 1201px) {
	.sub_list_box_wrap {
		min-height: 775px;
		/* min-height: 752px; */
	}
}

.img_txt_set img {
	width: 40px;
	height: 40px;
}
</style>
